import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import {
  StyledHeader,
  LeftSide,
  Subtitle,
  Title,
  Nav,
  // ThemeButton,
  GoodBriefLink,
} from "./styles";

const ThemeEnum = {
  Light: "light",
  Dark: "dark",
};

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: .25em;
  ion-icon {
    color: var(--brand-neutral-600);
    font-size: 1.5em;
  }
  @media (max-width: 460px) {
    p {
      display: none;
    }
  }
`;

const LogoImg = styled.img`
  height: 32px;
`;

const Header = () => {
  const [theme] = useState(ThemeEnum.Light);
  // const changeTheme = () => {
  //   setTheme(theme === ThemeEnum.Light ? ThemeEnum.Dark : ThemeEnum.Light);
  // };
  useEffect(() => {
    if (theme === ThemeEnum.Light) {
    }
  }, [theme]);
  return (
    <StyledHeader>
      <LeftSide>
        <Title>
          <a href="/">
            <LogoImg src="/logo32.png" />
            good<strong>palette</strong>
          </a>
        </Title>
        <Subtitle>Make beautiful color palettes for UI.</Subtitle>
      </LeftSide>
      <Nav>
        <p>
          <StyledLink to="/how-to" ><ion-icon name="information-circle-outline" /> <p>How To</p></StyledLink>
        </p>
        <GoodBriefLink href="https://goodbrief.io">
          <img src="https://goodbrief.io/favicons/favicon-32x32.png" alt="" />
          <p>
            good<strong>brief</strong>
          </p>
        </GoodBriefLink>
        {/* <ThemeButton onClick={changeTheme}>
          <ion-icon name="moon" size="small"></ion-icon>
        </ThemeButton> */}
      </Nav>
    </StyledHeader>
  );
};

export default Header;
