import { useEffect, useState } from "react";
import {
  defaultPaletteContext,
  PaletteContext,
} from "@contexts/PaletteContext";

const PaletteProvider = ({ children }) => {
  const [palette, setPalette] = useState(defaultPaletteContext.palette);
  const [lockedColors, setLockedColors] = useState({ primary: false, accent: false, neutral: false });

  const getParams = () => {
    const lockedString = '?'
      + (lockedColors['primary'] ? 'lp=' + JSON.stringify(palette.primary) + '&' : '')
      + (lockedColors['accent'] ? 'la=' + JSON.stringify(palette.accent) + '&' : '')
      + (lockedColors['neutral'] ? 'ln=' + JSON.stringify(palette.neutral) + '&' : '');
    return lockedString;
  }

  const getPalette = async () => {
    const params = getParams();
    const fetchString = "/api/palette" + params;
    const response = await fetch(fetchString);
    const json = await response.json();
    setPalette(json);
  };

  const getPaletteFromColor = async (p = '', a = '', n = '') => {
    try {
      const params = getParams();
      const response = await fetch(`/api/palette${params}&p=${p}&a=${a}&n=${n}`);
      const json = await response.json();
      setPalette(json);
    } catch (e) {
      console.error(e);
      getPalette();
    }
  };

  useEffect(() => {
    let pathname = window.location.pathname.substring(1);
    const [p, a, n] = pathname.split('-');
    if (p && a && n) {
      getPaletteFromColor(p, a, n);
    } else {
      getPalette();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const primary = palette.primary.shades[500];
    const accent = palette.accent.shades[500];
    const neutral = palette.neutral.shades[500];

    const currentUrl = window.location.origin;
    const newUrl = `${currentUrl}/${primary}-${accent}-${neutral}`.toLowerCase();

    window.history.pushState(null, '', newUrl);
  }, [palette]);

  const toggleColorLock = (colorType) => {
    setLockedColors({ ...lockedColors, [colorType]: !lockedColors[colorType] })
  }

  const paletteContext = {
    palette,
    lockedColors,
    getPalette,
    getPaletteFromColor,
    toggleColorLock
  };

  return (
    <PaletteContext.Provider value={paletteContext}>
      {children}
    </PaletteContext.Provider>
  );
};

export default PaletteProvider;
