import {
  Header
} from "./components";
import "./App.css";
import styled from "styled-components";

const Wrapper = styled.div`
  header {
    height: 70px;
  }
`;

const Content = styled.div`
  max-width: 740px;
  margin: 0 auto;
  padding: 3em 2em;
  h1, h2, p {
    color: var(--brand-neutral-700);
  }
  a {
    color: var(--brand-primary-500);
  }
`

const Title = styled.h1`
  font-family: "Poppins", "Nunito Sans", Roboto, "Open Sans", Tahoma, Geneva,
  "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 3em;
  line-height: 1.3em;
  @media (max-width: 420px) {
    font-size: 2em;
  }
`

const StyledP = styled.p`
  font-size: 1.25em;
  line-height: 1.75em;
`;

const ImagesWrapper = styled.div`
background: var(--brand-neutral-200);
padding: .5em;
border-radius: 10px;
display: flex;
align-items: center;
margin: 2em 0;
img {
  max-width: 100%;
  border-radius: 10px;
}
`;

const VideoWrapper = styled.video`
  max-width: 100%;
  margin: .5em 0;
`

const HowTo = () => {
  return (
    <Wrapper>
      <Header />
      <Content>
        <Title>How To Create a Beautiful Color&nbsp;Palette for your Website or&nbsp;App</Title>
        <StyledP>There are a lot of great color palette generators out there that give you four or five nice-looking colors that pair well together (I’ve included a list of my favorites at the end). When creating a color palette for user interfaces, though, that approach often does not work well.</StyledP>
        <StyledP>If we look at some websites with strong color branding, we can see some commonalities.</StyledP>
        <ImagesWrapper>
          <a href='/assets/example-airbnb.png' target='_blank'>
            <img src='/assets/example-airbnb.png' alt="A screenshot of the AirBnb website." />
          </a>
          <a href='/assets/example-digitalocean.png' target='_blank'>
            <img src='/assets/example-digitalocean.png' alt="A screenshot of the DigitalOcean website." />
          </a>
        </ImagesWrapper>
        <StyledP>We don’t see five different colors throughout the page. Instead, most of the page is actually made up of various shades of gray. Outside of images and illustrations, we typically only have one primary color: the brand color. Sometimes, a secondary accent color is used to highlight key elements, but it should be used sparingly.</StyledP>
        <StyledP>Using too many colors is often distracting. Limiting your color palette to one primary color, one accent color, and a strong foundation of neutral tones can help your design feel consistent.</StyledP>
        <ImagesWrapper>
          <a href='/assets/example-palette.png' target='_blank'>
            <img src='/assets/example-palette.png' alt="A screenshot of an example color palette." />
          </a>
          <a href='/assets/example-mockup.png' target='_blank'>
            <img src='/assets/example-mockup.png' alt="A screenshot of a mockup using the colors from the example palette." />
          </a>
        </ImagesWrapper>
        <StyledP>
          Goodpalette provides you with just that. Each color has nine shades that have been carefully picked to be used for text, backgrounds, wells, buttons, and more. You can also see how the color will look on different types of websites with the live mockups. It’s one thing to look at a selection of color swatches in isolation, but seeing it in the context of a real application is key.
        </StyledP>
        <StyledP>
          You can also easily export your palette as an SVG or CSS/SCSS variables. (Tip: click Export SVG, and then paste directly into your design app like Figma or Sketch!)
        </StyledP>
        <VideoWrapper autoplay controls loop muted src="/assets/goodpalette-copy-paste.mov" />
        <StyledP>
          Of course, no app will never be as good as a human with experience and an eye for design. I suggest using this as a starting-off point, and to spend some time editing the color palettes to be perfect.
        </StyledP>
        <StyledP>
          To manually fine-tune your color palette, I recommend <a href='https://palettte.app'>palettte.app</a> by <a href='https://twitter.com/gabdorf'>@gabdorf</a> (I actually used this app to study the swatches I was generating and find improvements).
        </StyledP>
        <StyledP>
          I hope you find Goodpalette useful! Feel free to reach out to me on <a href="https://twitter.com/manutastic">Twitter</a>, I would love to see what you make!
        </StyledP>
        <h2>Resources</h2>
        <StyledP> Articles about designing color palettes for UI: </StyledP>
        <StyledP><a href="https://gabrieladorf.com/palettteapp/">https://gabrieladorf.com/palettteapp/</a></StyledP>
        <StyledP><a href="https://www.refactoringui.com/previews/building-your-color-palette">https://www.refactoringui.com/previews/building-your-color-palette</a></StyledP>
        <StyledP><a href="https://learnui.design/blog/color-in-ui-design-a-practical-framework.html">https://learnui.design/blog/color-in-ui-design-a-practical-framework.html</a></StyledP>
        <StyledP>Non-UI color palette generators:</StyledP>
        <StyledP><a href="https://coolors.co">https://coolors.co</a></StyledP>
        <StyledP><a href="https://colorhunt.co">https://colorhunt.co</a></StyledP>
        <StyledP>Other:</StyledP>
        <StyledP><a href="https://accessiblepalette.com/">https://accessiblepalette.com/</a></StyledP>
        <StyledP><a href="https://brandcolors.net">https://brandcolors.net</a></StyledP>
        <StyledP><a href="https://duotone.shapefactory.co/">https://duotone.shapefactory.co/</a></StyledP>
      </Content>
    </Wrapper>
  );
};
export default HowTo;
