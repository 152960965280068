import React from "react";

export const defaultPaletteContext = {
  palette: {
    primary: {
      shades: {
        100: "F9F9F9",
        200: "DCDCDC",
        300: "BFBFBF",
        400: "A2A2A2",
        500: "848484",
        600: "676767",
        700: "4A4A4A",
        800: "2D2D2D",
        900: "101010",
      },
      name: "Loading...",
    },
    accent: {
      shades: {
        100: "F9F9F9",
        200: "DCDCDC",
        300: "BFBFBF",
        400: "A2A2A2",
        500: "848484",
        600: "676767",
        700: "4A4A4A",
        800: "2D2D2D",
        900: "101010",
      },
      name: "Loading...",
    },
    neutral: {
      shades: {
        100: "F9F9F9",
        200: "DCDCDC",
        300: "BFBFBF",
        400: "A2A2A2",
        500: "848484",
        600: "676767",
        700: "4A4A4A",
        800: "2D2D2D",
        900: "101010",
      },
    },
  },
  getPalette: async () => null,
};

export const PaletteContext = React.createContext(defaultPaletteContext);
