import { useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import useModal from '@hooks/useModal';
import { getAccessibleColor, checkWcagContrast, copyToClipboard } from "@utils";
import { IconButton } from "../../Buttons";
import { PaletteContext } from "../../../contexts/PaletteContext";
import WcagInfoPopup from "../../Modals/WcagInfoPopup";

const sizeMap = {
  primary: 180,
  accent: 120,
  neutral: 80,
};

const ColorCardWrapper = styled.div`
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  .hidden { 
    visibility: hidden;
    @media (max-width: 940px) {
      visibility: visible;
    }
  }
  &:hover {
    .hidden {
      visibility: visible;
    }
  }
`;

const WcagWrapper = styled.fieldset`
  color: var(--mainText);
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  margin-top: auto;
  @media (max-width: 940px) {
    display: none;
  }
  `;

const WcagCheckbox = styled.input`
  cursor: pointer;
  background: transparent;
  appearance: none;
  border: 2px solid currentColor;
  height: 1.15em;
  width: 1.15em;
  border-radius: 3px;
  margin: 0 .5em 0 0;
  transition: background .1s ease, box-shadow .1s ease;
  box-shadow: inset 0 0 0 0px var(--mainColor);
  &:checked {
    box-shadow: inset 0 0 0 2px var(--mainColor);
    background: currentColor;
  }
  `;
const WcagLabel = styled.label`
  cursor: pointer;
  `;

const WcagInfoIcon = styled(IconButton)`
  position: relative;
  background: transparent;
  --ionicon-stroke-width: 45px;
  transition: background .1s ease;
  margin: 0;
  margin-left: .25em;
  padding: 0;
  height: fit-content;
  &:hover {
    background: transparent;
  }
  ion-icon {
    color: var(--mainText);
    font-size: 1.4em;
  }
  @media (max-height: 1040px) {
    ion-icon {
      font-size: 1em;
    }
  }
`;

const Wcag = styled.span`
  color: #${(props) => props.text};
  position: absolute;
  text-transform: uppercase;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  font-size: .9em;
  font-weight: bold;
`;

const Controls = styled.div`
display: flex;
height: fit-content;
align-items: center;
`;

const MainColor = styled.div`
  background: #${(props) => props.color};
  height: 100%;
  max-height: ${(props) => sizeMap[props.type]}px;
  padding: 1em;
  flex-basis: ${(props) => sizeMap[props.type]}px;
  display: flex;
  justify-content: space-between;
  @media (max-height: 1040px) and (max-width: 940px) {
    flex-basis: 0;
    height: auto;
    padding: .25em;
  }
`;

const ColorInfo = styled.div`
display: flex;
flex-direction: column;
@media (max-height: 1040px) and (max-width: 940px) {
  flex-direction: row;
  align-items: center;
  padding: .25em;
}
`;

const ColorTitle = styled.h2`
  color: #${(props) => props.color};
  margin: 0;
  @media (max-width: 940px) {
    font-size: 1em;
  }
`;

const ColorCode = styled.p`
  color: #${(props) => props.color};
  display: flex;
  align-items: center;
  gap: .25em;
  margin: 0;
  cursor: pointer;
  @media (max-width: 940px) {
    margin-left: ${(props) => props.type !== 'neutral' ? '1em' : '0'};
  }
`;

const LockButton = styled(IconButton)`
  background: transparent;
  --ionicon-stroke-width: 45px;
  transition: background .1s ease;
  margin: 0;
  height: fit-content;
  &:hover {
    background: rgba(0,0,0,0.1);
  }
  ion-icon {
    color: #${(props) => props.color};
    font-size: 1.4em;
  }
  @media (max-height: 1040px) {
    ion-icon {
      font-size: 1em;
    }
  }
`;

const WcagButton = styled(IconButton)`
  display: none;
  background: transparent;
  --ionicon-stroke-width: 45px;
  transition: background .1s ease;
  margin: 0;
  font-size: 1.1em;
  --ionicon-stroke-width: 30px;
  &:hover {
    background: rgba(0,0,0,0.1);
  }
  ion-icon {
    color: #${(props) => props.color};
    font-size: 1.4em;
  }
  @media (max-width: 940px) {
    display: flex;
  }
  @media (max-height: 1040px) {
    ion-icon {
      font-size: 1.2em;
    }
  }
`;

const Shade = styled.div`
  position: relative;
  width: 11.111111111111%;
  padding-bottom: 11.111111111111%;
  background: #${(props) => props.color};
  cursor: pointer;
  transition: box-shadow .3s ease;
  box-shadow: inset 0 0 0px 0px white;
  &:hover {
    box-shadow: inset 0 0 0px 7px white;
  }
`;

const ShadesWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  `;

const ColorCard = (props) => {
  const { palette, type } = props;
  const { lockedColors, toggleColorLock } = useContext(PaletteContext);
  const wcagInfoRef = useRef(null);
  const colorCardRef = useRef(null);
  const { isOpen, openModal, closeModal } = useModal();
  const shades = props.palette.shades;
  const shadeKeys = Object.keys(props.palette.shades);
  const [justCopied, setJustCopied] = useState(false);
  const [showWcag, setShowWcag] = useState(false);
  const [mainColor, setMainColor] = useState(shades[500]);
  const [textColor, setTextColor] = useState(
    getAccessibleColor(mainColor, Object.values(shades))
  );
  const [secondaryTextColor, setSecondaryTextColor] = useState(
    getAccessibleColor(mainColor, Object.values(shades), true)
  );

  const lockIcon = lockedColors[type] ? 'lock-closed' : 'lock-open-outline';
  const wcagButtonIcon = showWcag ? 'accessibility' : 'accessibility-outline';

  const handleHexCodeClick = (color) => {
    copyToClipboard('#' + color);
    // Trigger confirmation animation
    if (!justCopied) {
      setJustCopied(true);
    }
  }

  const handleShadeClick = (color) => {
    setMainColor(color);
  }

  const handleLockClick = () => {
    toggleColorLock(type);
  }

  const handleWcagCheck = () => {
    setShowWcag(!showWcag);
  }

  useEffect(() => {
    setMainColor(shades[500]);
  }, [palette, shades]);

  useEffect(() => {
    setTextColor(getAccessibleColor(mainColor, Object.values(shades)));
    setSecondaryTextColor(
      getAccessibleColor(mainColor, Object.values(shades), true)
    );
  }, [mainColor, shades]);

  useEffect(() => {
    if (justCopied) {
      setTimeout(() => {
        setJustCopied(false);
      }, 2000);
    }
  }, [justCopied]);

  useEffect(() => {
    const handleLeaveCard = e => {
      if (!showWcag) {
        closeModal();
      }
    }

    if (colorCardRef) {
      const element = colorCardRef.current;
      element.addEventListener("mouseleave", handleLeaveCard);
      return () => {
        element.removeEventListener("mouseleave", handleLeaveCard);
      }
    }
  }, [closeModal]);

  const styles = {
    '--mainColor': `#${mainColor}`,
    '--mainText': `#${textColor}`,
    '--secondaryText': `#${secondaryTextColor}`,
  };

  return (
    <ColorCardWrapper style={styles} ref={colorCardRef}>
      <WcagInfoPopup isOpen={isOpen} closeModal={closeModal} root={wcagInfoRef.current} />
      <MainColor color={mainColor} type={type}>
        <ColorInfo>
          {palette.name && (
            <ColorTitle color={textColor}>{palette.name}</ColorTitle>
          )}
          <ColorCode color={secondaryTextColor} type={type} onClick={() => handleHexCodeClick(mainColor)}>
            #{mainColor}
            {justCopied ?
              [<ion-icon name="checkmark-outline"></ion-icon>, 'Copied']
              : <ion-icon class={"hidden"} name="copy-outline"></ion-icon>
            }
          </ColorCode>
          <WcagWrapper color={textColor} className={showWcag ? '' : 'hidden'}>
            <WcagCheckbox type="checkbox" id={`${type}-wcag`} onClick={handleWcagCheck}></WcagCheckbox>
            <WcagLabel htmlFor={`${type}-wcag`}>Show WCAG Contrast</WcagLabel>
            <WcagInfoIcon ref={wcagInfoRef} onClick={openModal}>
              <ion-icon name="help-circle-outline"></ion-icon>
            </WcagInfoIcon>
          </WcagWrapper>
        </ColorInfo>
        <Controls className={lockedColors[type] ? '' : 'hidden'}>
          <LockButton color={secondaryTextColor} onClick={handleLockClick}>
            <ion-icon name={lockIcon}></ion-icon>
          </LockButton>
          <WcagButton color={secondaryTextColor} onClick={handleWcagCheck}>
            <ion-icon name={wcagButtonIcon}></ion-icon>
          </WcagButton>
        </Controls>
      </MainColor>
      <ShadesWrapper>
        {shadeKeys.map((shade) => {
          const isMainColor = shades[shade] === mainColor;
          const wcag = showWcag && checkWcagContrast(shades[shade], mainColor);
          const textColor = showWcag && getAccessibleColor(shades[shade], Object.values(shades));
          return (
            <Shade
              key={`${type}-${shade}`}
              color={shades[shade]}
              showWcag={showWcag}
              onClick={() => handleShadeClick(shades[shade])}
            >
              {showWcag && !isMainColor && <Wcag text={textColor}>{wcag ? wcag : <ion-icon name={'close'}></ion-icon>}</Wcag>}
            </Shade>
          );
        })}
      </ShadesWrapper>
    </ColorCardWrapper>
  );
};

export default ColorCard;
