import { useContext } from "react";
import styled from "styled-components";
import { PaletteContext } from "../../../contexts/PaletteContext";
import { getAccessibleColor } from "@utils";

const MockupWrapper = styled.div`
  position: relative;
  border-radius: 5px;
  width: var(--mockup-width);
  height: var(--mockup-height);
  background: var(--palette-n-100);
  color: var(--palette-n-900);
  overflow: hidden;
  z-index: 0;
  display: flex;
  flex-direction: column;
  font-size: 0.9em;
`;

const Main = styled.div`
  display: flex;
  height: 100%;
`;

const Sidebar = styled.div`
  background: var(--sidebar);
  color: var(--sidebar-t);
  height: 100%;
  min-width: 30%;
`;

const Company = styled.li`
  height: 50px;
  padding: .75em 1.25em;
  margin-bottom: .25em;
  width: 100%;
  font-size: .8em;
  font-weight: 600;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--sidebar-dark)
`;

const Title = styled.li`
  color: var(--sidebar-t2);
  padding: 1.5em 2.25em .5em;
  width: 100%;
  font-size: .5em;
  text-transform: uppercase;
  letter-spacing: .1em;
  font-weight: 600;
  list-style: none;
`;

const MenuItem = styled.li`
  padding: 1em 1.5em;
  width: 100%;
  font-size: .7em;
  font-weight: 600;
  list-style: none;
  display: flex;
  align-items: center;
  ion-icon {
    margin-right: .5em;
    margin-top: -2px;
    color: var(--sidebar-t2); 
    --ionicon-stroke-width: 50px;
  }
`;
const Channel = styled.li`
  padding: .75em 1.5em;
  width: 100%;
  font-size: .7em;
  font-weight: 600;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.active {
    background: var(--sidebar-dark);
    color: var(--sidebar-dark-t);
  }
`;

const Channels = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const AddChannel = styled.li`
  color: var(--palette-p-800);
  padding: .75em 1em;
  border-radius: 5px;
  font-size: .6em;
  font-weight: 700;
  list-style: none;
  background: var(--palette-p-400);
  margin: auto 2em 2em;
  display: flex;
  align-items: center;
  justify-content: center;;
  justify-self: flex-end;
  ion-icon {
    margin-right: .25em;
    --ionicon-stroke-width: 50px;
  }
`;

const Divider = styled.hr`
  margin: 0;
  padding-top: .25em;
  border: none;
  border-bottom: 1px solid var(--sidebar-dark);
`;

const Badge = styled.span`
  background: var(--badge);
  color: var(--badge-t);
  font-size: .9em;
  border-radius: 999px;
  padding: 0 .75em;
  height: min-content;
`;
const Dot = styled.span`
  background: var(--badge);
  border-radius: 50%;
  height: 6px;
  width: 6px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ChannelInfo = styled.div`
  color: var(--palette-n-900);
  height: 50px;
  padding: .75em 1.25em;
  width: 100%;
  font-size: .8em;
  font-weight: 600;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--palette-n-200);
  box-shadow: 0 1px 3px var(--palette-n-200);
  flex-shrink: 0;
`;

const Avatar = styled.div`
  background: var(--palette-p-200);
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--palette-p-600);
  font-weight: 800;
`;

const Messages = styled.div`
  display: flex;
  flex-direction: column-reverse;
  font-size: 0.8em;
  height: 100%;
  overflow: hidden;
  justify-content: flex-start;
`;
const MessageWrapper = styled.div`
  display: flex;
  padding: 1em;
  ${Avatar} {
    height: 30px;
    width: 30px;
    flex-shrink: 0;
    margin-right: 1em;
  }
`;
const RightSection = styled.div``;
const Details = styled.div`
  display: flex;
  align-items: baseline;
`;
const Name = styled.div`
  font-weight: 700;
`;
const Time = styled.div`
  color: var(--palette-n-500);
  font-size: 0.9em;
  margin-left: .5em;
`;
const Message = styled.div``;
const Mention = styled.span`
  color: var(--palette-p-500);
  font-weight: 700;
`;

const InputBox = styled.div`
  margin 1em 2em 1.5em;
  background: var(--palette-n-200);
  color: var(--palette-n-700);
  border-radius: 5px;
  padding: .75em 1em;
  font-size: .7em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ion-icon {
    font-size: 1.2em;
    margin: 0 .25em;
    color: var(--palette-n-600);
  }
`;

const Slacklike = () => {
  const { palette } = useContext(PaletteContext);
  const pShades = palette.primary.shades;
  const aShades = palette.accent.shades;
  const getSubtleColor = (color, array) => {
    const accessibleColor = getAccessibleColor(color, array);
    const index = array.indexOf(accessibleColor);
    if (index > 4) {
      return array[index - 2];
    }
    return array[index + 2];
  };

  const sidebarColor = pShades[300];
  const sidebarColorDark = pShades[400];
  const styles = {
    "--sidebar": "#" + sidebarColor,
    "--sidebar-dark": "#" + sidebarColorDark,
    "--sidebar-dark-t": "#" + getAccessibleColor(sidebarColorDark, Object.values(pShades)),
    "--sidebar-t": "#" + getAccessibleColor(sidebarColor, Object.values(pShades)),
    "--sidebar-t2": "#" + getSubtleColor(sidebarColor, Object.values(pShades)),
    "--badge": "#" + aShades[400],
    "--badge-t": "#" + getAccessibleColor(aShades[400], Object.values(aShades)),
  }
  return (
    <MockupWrapper style={styles}>
      <Main>
        <Sidebar>
          <Channels>
            <Company>Acme Inc.<Dot /></Company>
            <MenuItem>
              <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
              Threads
            </MenuItem>
            <MenuItem>
              <ion-icon name="document-outline"></ion-icon>
              Drafts
            </MenuItem>
            <Divider />
            <Title>Channels</Title>
            <Channel># design <Badge>4</Badge></Channel>
            <Channel># dev</Channel>
            <Channel># marketing</Channel>
            <Channel className='active'># general</Channel>
            <Channel># random</Channel>
            <AddChannel>
              <ion-icon name="add-outline"></ion-icon>
              Add Channel
            </AddChannel>
          </Channels>
        </Sidebar>
        <Content>
          <ChannelInfo>
            General
            <Avatar>M</Avatar>
          </ChannelInfo>
          <Messages>
            <MessageWrapper>
              <Avatar>T</Avatar>
              <RightSection>
                <Details>
                  <Name>Robin Banks</Name>
                  <Time>12:52 PM</Time>
                </Details>
                <Message>
                  Oh wow, great job! I just added some ideas I had this morning to that folder. <Mention>@Sarah</Mention> could you take a look and let me know what you think?
                </Message>
              </RightSection>
            </MessageWrapper>
            <MessageWrapper>
              <Avatar>S</Avatar>
              <RightSection>
                <Details>
                  <Name>Samantha Ross</Name>
                  <Time>12:50 PM</Time>
                </Details>
                <Message>
                  Sweet, thank you so much. These are looking great!
                </Message>
              </RightSection>
            </MessageWrapper>
            <MessageWrapper>
              <Avatar>T</Avatar>
              <RightSection>
                <Details>
                  <Name>Tiffany Myers</Name>
                  <Time>12:50 PM</Time>
                </Details>
                <Message>
                  yep! I put a few photos in the shared folder. Let me know if you have any questions :)
                </Message>
              </RightSection>
            </MessageWrapper>
            <MessageWrapper>
              <Avatar>S</Avatar>
              <RightSection>
                <Details>
                  <Name>Samantha Ross</Name>
                  <Time>12:42 PM</Time>
                </Details>
                <Message>
                  Hey <Mention>@Tiffany</Mention>, could you share those sketches you showed me yesterday? I wanted to take another look.
                </Message>
              </RightSection>
            </MessageWrapper>
          </Messages>
          <InputBox>
            Message #general
            <ion-icon name="text-outline"></ion-icon>
          </InputBox>
        </Content>
      </Main>
    </MockupWrapper>
  );
};

export default Slacklike;
