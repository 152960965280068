import styled from "styled-components";
import { useContext } from "react";
import { PaletteContext } from "@contexts/PaletteContext";
import { IconButton } from "@components/Buttons";


const MockupSectionWrapper = styled.div`
  grid-row: mockup;
  grid-column: mockup;
  background: var(--brand-neutral-200);
  background-color: var(--brand-neutral-200);
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23bac4d9' fill-opacity='0.2' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  justify-content: center;
  z-index: 0;
`;

const ArrowButton = styled(IconButton)`
  position: absolute;
  background: var(--brand-neutral-100);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 3px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  :hover {
    background: var(--brand-neutral-200);
  }
`;

const LeftArrowButton = styled(ArrowButton)`
  left: 1em;
`;

const RightArrowButton = styled(ArrowButton)`
  right: 1em;
`;

const MockupWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const MockupSizer = styled.span`
  transition: transform 0.3s ease;
  transform: scale(1);
  @media (min-width: 1400px) and (min-height: 850px) {
    transform: scale(1.25);
  }
  @media (min-width: 1550px) and (min-height: 850px) {
    transform: scale(1.5);
  }
  @media (max-width: 1170px) {
    transform: scale(0.7);
  }
  @media (max-width: 940px) {
    transform: scale(1);
  }
  @media (max-width: 660px) {
    transform: scale(0.75);
  }
  @media (max-width: 530px) {
    transform: scale(0.5);
  }
  @media (max-height: 1040px) and (max-width: 940px) {
    transform: scale(0.65);
  }
  @media (max-height: 880px) and (max-width: 940px) {
    transform: scale(0.4);
  }
`;

const MockupFrame = styled.div`
  background: var(--brand-neutral-100);
  border: 4px solid var(--brand-neutral-100);
  border-radius: 6px;
  box-shadow: 0 0px 1px var(--brand-neutral-400), 0 2px 4px var(--brand-neutral-500),
    0 10px 6px var(--brand-neutral-300);
  display: flex;
  flex-direction: column;
  align-items: center;
  label {
    color: var(--brand-neutral-600);
    font-size: 0.7em;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    padding: 0.35em 0 0.5em;
  }
  ::before {
    content: "";
    display: block;
    position: absolute;
    background: var(--brand-neutral-200);
    height: 14px;
    width: 14px;
    border-radius: 50px;
    top: 0.5em;
    left: 0.5em;
    ::before {
      content: "";
      display: block;
      position: absolute;
      background: var(--brand-neutral-200);
      height: 14px;
      width: 14px;
      border-radius: 50px;
      top: 0.5em;
      left: 0.5em;
    }
  }
`;

const MockupSection = (props) => {
  const { mockup, handleNextMockup, handlePreviousMockup } = props;
  const { palette } = useContext(PaletteContext);
  var style = {
    "--palette-p-100": "#" + palette.primary.shades[100],
    "--palette-p-200": "#" + palette.primary.shades[200],
    "--palette-p-300": "#" + palette.primary.shades[300],
    "--palette-p-400": "#" + palette.primary.shades[400],
    "--palette-p-500": "#" + palette.primary.shades[500],
    "--palette-p-600": "#" + palette.primary.shades[600],
    "--palette-p-700": "#" + palette.primary.shades[700],
    "--palette-p-800": "#" + palette.primary.shades[800],
    "--palette-p-900": "#" + palette.primary.shades[900],

    "--palette-a-100": "#" + palette.accent.shades[100],
    "--palette-a-200": "#" + palette.accent.shades[200],
    "--palette-a-300": "#" + palette.accent.shades[300],
    "--palette-a-400": "#" + palette.accent.shades[400],
    "--palette-a-500": "#" + palette.accent.shades[500],
    "--palette-a-600": "#" + palette.accent.shades[600],
    "--palette-a-700": "#" + palette.accent.shades[700],
    "--palette-a-800": "#" + palette.accent.shades[800],
    "--palette-a-900": "#" + palette.accent.shades[900],

    "--palette-n-100": "#" + palette.neutral.shades[100],
    "--palette-n-200": "#" + palette.neutral.shades[200],
    "--palette-n-300": "#" + palette.neutral.shades[300],
    "--palette-n-400": "#" + palette.neutral.shades[400],
    "--palette-n-500": "#" + palette.neutral.shades[500],
    "--palette-n-600": "#" + palette.neutral.shades[600],
    "--palette-n-700": "#" + palette.neutral.shades[700],
    "--palette-n-800": "#" + palette.neutral.shades[800],
    "--palette-n-900": "#" + palette.neutral.shades[900],
  };
  return (
    <MockupSectionWrapper style={style}>
      <MockupWrapper>
        <LeftArrowButton onClick={handlePreviousMockup}>
          <ion-icon name="arrow-back-outline" size="small"></ion-icon>
        </LeftArrowButton>
        <MockupSizer>
          <MockupFrame>
            <label>UI Mockup | {mockup.name} </label>
            {mockup.component}
          </MockupFrame>
        </MockupSizer>
        <RightArrowButton onClick={handleNextMockup}>
          <ion-icon name="arrow-forward-outline" size="small"></ion-icon>
        </RightArrowButton>
      </MockupWrapper>
    </MockupSectionWrapper>
  );
};

export default MockupSection;
