import styled from "styled-components";
import { IconButton } from "@components/Buttons";

const ControlsWrapper = styled.div`
  grid-row: m-controls;
  grid-column: m-controls;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  border-bottom: 1px solid var(--brand-neutral-200);
  background: var(--brand-neutral-100);
  @media (max-width: 940px) {
    justify-content: space-between;
    padding: 1em;
  }
`;

const MockupTitle = styled.h3`
  font-weight: normal;
  font-size: 1.1em;
  color: var(--brand-neutral-700);
  width: 130px;
  text-align: center;
`;

const MockupControls = (props) => {
  const { mockupName, handleNextMockup, handlePreviousMockup } = props;

  return (
    <ControlsWrapper>
      <IconButton onClick={handlePreviousMockup}>
        <ion-icon name="arrow-back-outline" size="small"></ion-icon>
      </IconButton>
      <MockupTitle>{mockupName}</MockupTitle>
      <IconButton onClick={handleNextMockup}>
        <ion-icon name="arrow-forward-outline" size="small"></ion-icon>
      </IconButton>
    </ControlsWrapper>
  );
};
export default MockupControls;
