import { useContext } from "react";
import styled from "styled-components";
import { PaletteContext } from "../../../contexts/PaletteContext";
import { getAccessibleColor } from "@utils";

import { ReactComponent as GraphSvg } from './svg/graph.svg';
import { ReactComponent as WeekdaySvg } from './svg/weekday.svg';
import { ReactComponent as HappySvg } from './svg/happy.svg';
import { ReactComponent as PieSvg } from './svg/pie.svg';
import { ReactComponent as ActivitySvg } from './svg/activity.svg';

const MockupWrapper = styled.div`
  position: relative;
  border-radius: 5px;
  width: var(--mockup-width);
  height: var(--mockup-height);
  background: var(--palette-n-200);
  color: var(--palette-n-900);
  overflow: hidden;
  z-index: 0;
  display: flex;
  flex-direction: column;
  font-size: 0.9em;
`;

const Header = styled.div`
  background: linear-gradient(to right, var(--palette-p-600), var(--palette-p-400));
  color: var(--header-text);
  height: 6em;
  display: flex;
  gap: .5em;
  align-items: center;
  padding: 1em 1em 3em;
`;

const Logo = styled.div`
  background: var(--palette-p-300);
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
`;

const Title = styled.div`
  font-size: 1em;
  font-weight: bold;
`;

const Nav = styled.div`
  display: flex;
  gap: 1em;
  margin-left: auto;
`;

const NavItem = styled.p`
font-size: .7em;
font-weight: bold;
${props => props.active && `
  &::after {
    content: '';
    display: block;
    background: var(--palette-p-300);
    border-radius: 50em;
    width: 120%;
    height: 2px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
  }
`}
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 1em;
  margin: -2em 1em 0 ;
`;
const MainSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 70%;
`;
const SidebarSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 30%;
`;
const GraphBox = styled(GraphSvg)`
  width: 100%;
  height: 198px;
  border-radius: 7px;
  box-shadow: 0px 8px 17px -17px var(--palette-n-900);
  position: relative;
  overflow: hidden;
  svg {
    position: relative;
    left: 0;
    height: auto;
    width: 100%;
  }
`;
const ActivityBox = styled(ActivitySvg)`
  width: 100%;
  height: 198px;
  border-radius: 7px;
  box-shadow: 0px 8px 17px -17px var(--palette-n-900);
  position: relative;
  overflow: hidden;
  svg {
    position: relative;
    left: 0;
    height: auto;
    width: 100%;
  }
`;
const WeekdayBox = styled(WeekdaySvg)`
  width: 175px;
  height: 175px;
  border-radius: 7px;
  box-shadow: 0px 8px 17px -17px var(--palette-n-900);
  position: relative;
  overflow: hidden;
  svg {
    position: relative;
    left: 0;
    height: auto;
    width: 100%;
  }
`;
const HappyBox = styled(HappySvg)`
  width: 175px;
  height: 70px;
  border-radius: 7px;
  box-shadow: 0px 8px 17px -17px var(--palette-n-900);
  position: relative;
  overflow: hidden;
  svg {
    position: relative;
    left: 0;
    height: auto;
    width: 100%;
  }
`;
const PieBox = styled(PieSvg)`
  width: 175px;
  height: 175;
  border-radius: 7px;
  box-shadow: 0px 8px 17px -17px var(--palette-n-900);
  position: relative;
  overflow: hidden;
  svg {
    position: relative;
    left: 0;
    height: auto;
    width: 100%;
  }
`;

const Dashboard = () => {
  const { palette } = useContext(PaletteContext);
  const pShades = palette.primary.shades;
  const styles = {
    "--header-text": "#" + getAccessibleColor(pShades[600], Object.values(pShades)),
  }
  return (
    <MockupWrapper style={styles}>
      <Header>
        <Logo />
        <Title>Acme Dashboard</Title>
        <Nav>
          <NavItem active={true}>Dashboard</NavItem>
          <NavItem>Account</NavItem>
          <NavItem>Inbox</NavItem>
          <NavItem>Help</NavItem>
          <NavItem>Settings</NavItem>
        </Nav>
      </Header>
      <ContentWrapper>
        <MainSection>
          <GraphBox />
          <ActivityBox />
        </MainSection>
        <SidebarSection>
          <WeekdayBox />
          <HappyBox />
          <PieBox />
        </SidebarSection>
      </ContentWrapper>
    </MockupWrapper>
  );
};

export default Dashboard;
