import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { copyToClipboard } from "@utils";
import { PaletteContext } from '@contexts/PaletteContext';

const modalRoot = document.getElementById('modal-root');

const Modal = styled.div`
  position: absolute;
  top: 2em;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
  z-index: 1;
  &::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom: 10px solid var(--brand-neutral-100);
    top: -10px;
    z-index: 1;
  }
  @media (max-width: 940px) {
    top: .5em;
    transform: translate(-50%, -100%);
    &::before {
      display: none;
    }
    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top: 10px solid var(--brand-neutral-100);
      top: 0px;
    }
  }
`;

const ModalContent = styled.div`
  background: var(--brand-neutral-100);
  color: var(--brand-neutral-800);
  border-radius: 5px;
  padding: 1em;
  box-shadow: 0 18px 10px -20px var(--brand-neutral-700), 0 10px 50px -20px var(--brand-neutral-700);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: .5em;
  p {
    margin: 0;
  }
`;

const ItemIcon = styled('div')`
position: relative;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  padding: .5em;
  font-size: 1.5em;
  background: var(--brand-neutral-300);
  ion-icon {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const MenuItem = styled.button`
  display: flex;
  border: none;
  background: var(--brand-neutral-200);
  width: max-content;
  align-items: center;
  gap: 1em;
  border-radius: 50em;
  padding: .5em;
  width: 11em;
  cursor: pointer;
  &:hover {
    background: var(--brand-neutral-300);
    ${ItemIcon} {
      background: var(--brand-neutral-400);
    }
  }
`;

const Toast = styled.div`
  position: absolute;
  display: block;
  top: ${props => props.posY - 27}px;
  left: ${props => props.posX - 40}px;
  transform: translate(-50px, -100px);
  background: var(--brand-neutral-100);
  border-radius: 50em;
  padding: .25em 1em;
  box-shadow: 0 2px 5px -4px var(--brand-neutral-700);
  font-size: .9em;
  -webkit-animation: flip-in-hor-top .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: flip-in-hor-top .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-iteration-count: 2;
  animation-direction: alternate;
`;

const ExportMenu = ({ isOpen, closeModal, root }) => {
  const modalRef = useRef();
  const { palette } = useContext(PaletteContext);
  const [justCopied, setJustCopied] = useState(false);
  const [mousePos, setMousePos] = useState([0, 0]);

  const handleKeydown = useCallback((event) => {
    if (event.keyCode === 27) {
      closeModal();
    }
  }, [closeModal]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleKeydown, false);
    }
    return () => {
      document.removeEventListener('keydown', handleKeydown, false);
    };
  }, [isOpen, handleKeydown]);

  useEffect(() => {
    const handleClickOutside = e => {
      if (isOpen) {
        if (modalRef && modalRef.current && !justCopied) {
          const modalRect = modalRef.current.getBoundingClientRect()
          setMousePos([e.x - modalRect.left, e.y - modalRect.top]);
        }
        if (modalRef && modalRef.current && modalRef.current.contains(e.target)) {
          return;
        }
        closeModal();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [closeModal, justCopied, isOpen]);

  useEffect(() => {
    if (justCopied) {
      setTimeout(() => {
        setJustCopied(false);
      }, 1000);
    }
  }, [justCopied]);

  const copyUrl = () => {
    copyToClipboard(window.location.href);
    if (!justCopied) {
      setJustCopied(true);
    }
  }

  const copyCss = () => {
    const cssProperties = `
    /* Primary: ${palette.primary.name} */
    --primary-100: #${palette.primary.shades[100]};
    --primary-200: #${palette.primary.shades[200]};
    --primary-300: #${palette.primary.shades[300]};
    --primary-400: #${palette.primary.shades[400]};
    --primary-500: #${palette.primary.shades[500]};
    --primary-600: #${palette.primary.shades[600]};
    --primary-700: #${palette.primary.shades[700]};
    --primary-800: #${palette.primary.shades[800]};
    --primary-900: #${palette.primary.shades[900]};

    /* Accent: ${palette.accent.name} */
    --accent-100: #${palette.accent.shades[100]};
    --accent-200: #${palette.accent.shades[200]};
    --accent-300: #${palette.accent.shades[300]};
    --accent-400: #${palette.accent.shades[400]};
    --accent-500: #${palette.accent.shades[500]};
    --accent-600: #${palette.accent.shades[600]};
    --accent-700: #${palette.accent.shades[700]};
    --accent-800: #${palette.accent.shades[800]};
    --accent-900: #${palette.accent.shades[900]};

    /* Neutral */
    --neutral-100: #${palette.neutral.shades[100]};
    --neutral-200: #${palette.neutral.shades[200]};
    --neutral-300: #${palette.neutral.shades[300]};
    --neutral-400: #${palette.neutral.shades[400]};
    --neutral-500: #${palette.neutral.shades[500]};
    --neutral-600: #${palette.neutral.shades[600]};
    --neutral-700: #${palette.neutral.shades[700]};
    --neutral-800: #${palette.neutral.shades[800]};
    --neutral-900: #${palette.neutral.shades[900]};
    `;
    copyToClipboard(cssProperties);
    if (!justCopied) {
      setJustCopied(true);
    }
  }

  const copyScss = () => {
    const scssVariables = `
    /* Primary: ${palette.primary.name} */
    $primary-100: #${palette.primary.shades[100]};
    $primary-200: #${palette.primary.shades[200]};
    $primary-300: #${palette.primary.shades[300]};
    $primary-400: #${palette.primary.shades[400]};
    $primary-500: #${palette.primary.shades[500]};
    $primary-600: #${palette.primary.shades[600]};
    $primary-700: #${palette.primary.shades[700]};
    $primary-800: #${palette.primary.shades[800]};
    $primary-900: #${palette.primary.shades[900]};

    /* Accent: ${palette.accent.name} */
    $accent-100: #${palette.accent.shades[100]};
    $accent-200: #${palette.accent.shades[200]};
    $accent-300: #${palette.accent.shades[300]};
    $accent-400: #${palette.accent.shades[400]};
    $accent-500: #${palette.accent.shades[500]};
    $accent-600: #${palette.accent.shades[600]};
    $accent-700: #${palette.accent.shades[700]};
    $accent-800: #${palette.accent.shades[800]};
    $accent-900: #${palette.accent.shades[900]};

    /* Neutral */
    $neutral-100: #${palette.neutral.shades[100]};
    $neutral-200: #${palette.neutral.shades[200]};
    $neutral-300: #${palette.neutral.shades[300]};
    $neutral-400: #${palette.neutral.shades[400]};
    $neutral-500: #${palette.neutral.shades[500]};
    $neutral-600: #${palette.neutral.shades[600]};
    $neutral-700: #${palette.neutral.shades[700]};
    $neutral-800: #${palette.neutral.shades[800]};
    $neutral-900: #${palette.neutral.shades[900]};
    `;
    copyToClipboard(scssVariables);
    if (!justCopied) {
      setJustCopied(true);
    }
  }

  const copySvg = () => {
    const svgCode = `
      <svg width="360" height="120">
        <rect width="40" height="40" x="0" y="0" fill="#${palette.primary.shades[100]}" />
        <rect width="40" height="40" x="40" y="0" fill="#${palette.primary.shades[200]}" />
        <rect width="40" height="40" x="80" y="0" fill="#${palette.primary.shades[300]}" />
        <rect width="40" height="40" x="120" y="0" fill="#${palette.primary.shades[400]}" />
        <rect width="40" height="40" x="160" y="0" fill="#${palette.primary.shades[500]}" />
        <rect width="40" height="40" x="200" y="0" fill="#${palette.primary.shades[600]}" />
        <rect width="40" height="40" x="240" y="0" fill="#${palette.primary.shades[700]}" />
        <rect width="40" height="40" x="280" y="0" fill="#${palette.primary.shades[800]}" />
        <rect width="40" height="40" x="320" y="0" fill="#${palette.primary.shades[900]}" />

        <rect width="40" height="40" x="0" y="40" fill="#${palette.accent.shades[100]}" />
        <rect width="40" height="40" x="40" y="40" fill="#${palette.accent.shades[200]}" />
        <rect width="40" height="40" x="80" y="40" fill="#${palette.accent.shades[300]}" />
        <rect width="40" height="40" x="120" y="40" fill="#${palette.accent.shades[400]}" />
        <rect width="40" height="40" x="160" y="40" fill="#${palette.accent.shades[500]}" />
        <rect width="40" height="40" x="200" y="40" fill="#${palette.accent.shades[600]}" />
        <rect width="40" height="40" x="240" y="40" fill="#${palette.accent.shades[700]}" />
        <rect width="40" height="40" x="280" y="40" fill="#${palette.accent.shades[800]}" />
        <rect width="40" height="40" x="320" y="40" fill="#${palette.accent.shades[900]}" />
        
        <rect width="40" height="40" x="0" y="80" fill="#${palette.neutral.shades[100]}" />
        <rect width="40" height="40" x="40" y="80" fill="#${palette.neutral.shades[200]}" />
        <rect width="40" height="40" x="80" y="80" fill="#${palette.neutral.shades[300]}" />
        <rect width="40" height="40" x="120" y="80" fill="#${palette.neutral.shades[400]}" />
        <rect width="40" height="40" x="160" y="80" fill="#${palette.neutral.shades[500]}" />
        <rect width="40" height="40" x="200" y="80" fill="#${palette.neutral.shades[600]}" />
        <rect width="40" height="40" x="240" y="80" fill="#${palette.neutral.shades[700]}" />
        <rect width="40" height="40" x="280" y="80" fill="#${palette.neutral.shades[800]}" />
        <rect width="40" height="40" x="320" y="80" fill="#${palette.neutral.shades[900]}" />
      </svg>
    `;
    copyToClipboard(svgCode);
    if (!justCopied) {
      setJustCopied(true);
    }
  }

  return isOpen ? createPortal(
    <>
      <Modal ref={modalRef}>
        <ModalContent>
          <MenuItem onClick={copyUrl}>
            <ItemIcon><ion-icon name="link-outline"></ion-icon></ItemIcon>
            Copy URL
          </MenuItem>
          <MenuItem onClick={copyCss}>
            <ItemIcon><ion-icon name="code-slash-outline"></ion-icon></ItemIcon>
            Copy CSS
          </MenuItem>
          <MenuItem onClick={copyScss}>
            <ItemIcon><ion-icon name="code-working-outline"></ion-icon></ItemIcon>
            Copy SCSS
          </MenuItem>
          <MenuItem onClick={copySvg}>
            <ItemIcon><ion-icon name="shapes-outline"></ion-icon></ItemIcon>
            Copy SVG
          </MenuItem>
        </ModalContent>
        {justCopied &&
          <Toast posX={mousePos[0]} posY={mousePos[1]}>Copied!</Toast>
        }
      </Modal>
    </>, root || modalRoot) : null;
};

export default ExportMenu;