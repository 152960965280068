import { useState } from "react";
import {
  Header,
  Controls,
  Palette,
  MockupControls,
  MockupSection,
} from "./components";
import { AcmeMockup, Slacklike, Dashboard } from "@components/MockupSection/Mockups";
import { PaletteProvider } from "./providers";
import "./App.css";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas: "header header" "p-controls mockup" "palette mockup";
  grid-template-rows: 70px 120px auto;
  grid-template-columns: 500px auto;

  @media (max-width: 940px) {
    grid-template-areas: "header" "palette" "mockup" "p-controls";
    grid-template-rows: 60px calc(50vh - 70px) calc(50vh - 70px) 80px;
    grid-template-columns: 1fr;
    @media (max-height: 700px) {
      grid-template-rows: 60px calc(52vh - 70px) calc(48vh - 70px) 80px;
    }
  }

`;

const MockupOptions = [
  { name: "Dashboard", component: <Dashboard /> },
  { name: "Acme.com", component: <AcmeMockup /> },
  { name: "Slacklike", component: <Slacklike /> },
];

const App = () => {
  const [mockupIndex, setMockupIndex] = useState(0);

  const handleNextMockup = () => {
    const newIndex =
      mockupIndex + 1 >= MockupOptions.length ? 0 : mockupIndex + 1;
    setMockupIndex(newIndex);
  };
  const handlePreviousMockup = () => {
    const newIndex =
      mockupIndex - 1 < 0 ? MockupOptions.length - 1 : mockupIndex - 1;
    setMockupIndex(newIndex);
  };

  return (
    <PaletteProvider>
      <Wrapper>
        <Header />
        <Controls />
        <Palette />
        <MockupSection
          mockup={MockupOptions[mockupIndex]}
          handleNextMockup={handleNextMockup}
          handlePreviousMockup={handlePreviousMockup}
        />
      </Wrapper>
    </PaletteProvider>
  );
};
export default App;
