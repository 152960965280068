import { useContext } from "react";
import styled from "styled-components";
import { PaletteContext } from "../../../contexts/PaletteContext";
import { getAccessibleColor } from "@utils";

const MockupWrapper = styled.div`
  border-radius: 5px;
  width: var(--mockup-width);
  height: var(--mockup-height);
  background: var(--palette-n-100);
  color: var(--palette-n-900);
  overflow: hidden;
  z-index: 0;
`;

const Header = styled.div`
  color: var(--palette-n-900);
  font-size: 0.9em;
  font-weight: 700;
  display: flex;
  padding: 1em;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
`;
const Title = styled.div`
  display: flex;
 `;
const Logo = styled.div`
  height: 18px;
  width: 18px;
  background: var(--palette-p-500);
  border-radius: 50%;
  margin-right: .5em;
`;
const Nav = styled.div`
  color: var(--palette-p-800);
  display: flex;
  gap: 1em;
  font-size: 0.6em;
  align-items: center;
  margin-left: -2em;
  span {
    position: relative;
  }
`;
const InfoBubble = styled.div`
  position: absolute;
  top: 1px;
  right: -4px;
  background: var(--palette-a-500);
  color: var(--palette-a-100);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7px;
  font-weight: bolder;
`;
const MainSection = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
  padding-top: 2.25em;
  padding-bottom: 6.5em;
  margin-bottom: -5.5em;
  z-index: 2;
`;
const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5em;
  p {
    font-size: .5em;
    font-weight: normal;
    color: var(--palette-n-700);
    text-align: center;
    max-width: 350px;
  }
`;
const ActionButtons = styled.div`
  display: flex;
  gap: 1em;
`;
const PrimaryAction = styled.span`
  background: var(--button);
  color: var(--button-t);
  font-size: 0.3em;
  padding: 0.75em 2em;
  margin-top .5em;
  border-radius: 50px;
  box-shadow:  0 1px 4px -1px var(--palette-p-700);
`;
const SecondaryAction = styled.span`
  background: var(--palette-n-100);
  color: var(--palette-p-700);
  font-size: 0.3em;
  padding: 0.75em 2em;
  margin-top .5em;
  border-radius: 50px;
  box-shadow: 0 1px 1px var(--palette-n-200);
`;
const SignUp = styled.span`
  background: var(--palette-p-200);
  color: var(--palette-p-800);
  padding: 0.25em 1.5em;
  border-radius: 50px;
  align-self: center;
  margin-left: 1em;
  font-size: .5em;
`;
const Shapes = styled.div`
  height: 200px;
  width: 200px;
  background: linear-gradient(var(--palette-p-200) -40%, var(--palette-n-100) 100%);
  position: absolute;
  border-radius: 50%;
  margin-top: -2.5em;
  margin-bottom: -4em;
  z-index: -1;
  &::before {
    content: "";
    display: block;
    height: 40px;
    width: 40px;
    top: 0;
    right: -10em;
    position: absolute;
    background-image: radial-gradient(var(--palette-p-200) 30%, rgba(255,255,255,0) 31%),
      radial-gradient(var(--palette-p-200) 30%, rgba(255,255,255,0) 31%);
    background-size: 10px 10px;
    background-position: 0 0px, 0px 0px;
    background-repeat: repeat;
  }
  &::after {
    content: "";
    display: block;
    height: 40px;
    width: 40px;
    bottom: 0;
    left: -10em;
    position: absolute;
    background-image: radial-gradient(var(--palette-p-200) 30%, rgba(255,255,255,0) 31%),
      radial-gradient(var(--palette-p-200) 30%, rgba(255,255,255,0) 31%);
    background-size: 10px 10px;
    background-position: 0 0px, 0px 0px;
    background-repeat: repeat;
  }
`;
const Midsection = styled.div`
  position: relative;
  z-index: 3;
  margin: 2em auto;
  display: flex;
  justify-content: center;
`;
const Box = styled.div`
  position: relative;
  background: var(--palette-n-100);
  padding: 2.5em 2em 2em;
  width: 13em;
  margin: 0 0.5em;
  text-align: center;
  border-radius: 5px;
  font-size: .6em;
  font-weight: bold;
  color: var(--palette-p-800);
  border: 1px solid var(--palette-n-200);
  box-shadow:  0 2px 3px -1px var(--palette-n-200);
  p {
    font-weight: normal;
    color: var(--palette-n-600);
    margin: 0;
  }
  ion-icon {
    display: flex;
    font-size: 1.8em;
    padding: .45em;
    border-radius: 50%;
    top: 0;
    left: 50%;
    font-weight: bolder;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    position: absolute;
    background: var(--palette-p-200);
    color: var(--palette-p-500);
  }
`;
const TheRest = styled.div`
  color: var(--palette-n-600);
  font-weight: bold;
  display: flex;
  justify-content: center;
  font-size: 1.2em;
  position: relative;
  bottom: -0.75em;
`;

const AcmeMockup = () => {
  const { palette } = useContext(PaletteContext);
  const pShades = palette.primary.shades;
  const styles = {
    "--button": "#" + pShades[500],
    "--button-t": "#" + getAccessibleColor(pShades[500], Object.values(pShades)),
  };
  return (
    <MockupWrapper style={styles}>
      <Header>
        <Title><Logo />Acme Inc.</Title>
        <Nav>
          <span>About Us</span>
          <span>Pricing</span>
          <span>Support</span>
          <span>Updates <InfoBubble></InfoBubble> </span>
        </Nav>
        <SignUp>Sign Up</SignUp>
      </Header>
      <MainSection>
        <LeftSection>
          Definitely a real website.
          <p>Okay, not really. This is just a mockup so you can see how the color palette would look in a real-world scenario.</p>
          <ActionButtons>
            <PrimaryAction>Sign Up</PrimaryAction>
            <SecondaryAction>Learn More</SecondaryAction>
          </ActionButtons>
        </LeftSection>
        <Shapes />
      </MainSection>
      <Midsection>
        <Box>
          <ion-icon name="cube-outline"></ion-icon>
          Preview
          <p>See your color palette in practice.</p>
        </Box>
        <Box>
          <ion-icon name="bulb-outline"></ion-icon>
          Example
          <p>See how all the shades are used.</p>
        </Box>
        <Box>
          <ion-icon name="planet-outline"></ion-icon>
          Adapt
          <p>Modify the palette for your use-case!</p>
        </Box>
      </Midsection>
      <TheRest>The rest is just offscreen</TheRest>
    </MockupWrapper>
  );
};

export default AcmeMockup;
