import styled from "styled-components";

const StyledButton = styled.button`
  position: relative;
  background: var(--brand-neutral-300);
  border: none;
  padding: 0.5em 2em;
  border-radius: 50px;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background: var(--brand-neutral-400);
  }
  transition: background 0.2s ease;
`;

const IconButton = styled(StyledButton)`
  display: inline-flex;
  align-items: center;
  padding: 0.6em;
`;

const PrimaryButton = styled(StyledButton)`
  background: var(--brand-primary-200);
  color: var(--brand-primary-800);
  &:hover {
    background: var(--brand-primary-300);
  }
`;

const PrimaryIconButton = styled(PrimaryButton)`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0.6em;
`;

export { StyledButton, PrimaryButton, PrimaryIconButton, IconButton };
