import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import { PaletteContext } from '@contexts/PaletteContext';
import { StyledButton, PrimaryButton, PrimaryIconButton } from '../../Buttons';
import useModal from '@hooks/useModal';
import { GenerateFromPrimary } from '@components/Modals';
import { ExportMenu } from '@components/Modals';

const ControlsWrapper = styled.div`
  grid-row: p-controls;
  grid-column: p-controls;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  border-right: 1px solid var(--brand-neutral-200);
  border-bottom: 1px solid var(--brand-neutral-200);
  z-index: 2;
  @media (max-width: 940px) {
    padding: 1em;
    border: none;
    border-top: 1px solid var(--brand-neutral-200);
  }
`;

const Controls = () => {
  const { getPalette } = useContext(PaletteContext);
  const fromColorRef = useRef(null);
  const exportRef = useRef(null);
  const { isOpen: fromColorIsOpen, openModal: openFromColorModal, closeModal: closeFromColorModal } = useModal();
  const { isOpen: exportIsOpen, openModal: openExportModal, closeModal: closeExportModal } = useModal();

  const handleGetPalette = () => {
    getPalette();
  };
  return (
    <ControlsWrapper>
      <PrimaryButton onClick={handleGetPalette}>Generate</PrimaryButton>
      <PrimaryIconButton onClick={openFromColorModal} ref={fromColorRef}>
        <ion-icon name="eyedrop" size="small"></ion-icon>
      </PrimaryIconButton>
      <StyledButton onClick={openExportModal} ref={exportRef}>Export</StyledButton>

      <GenerateFromPrimary isOpen={fromColorIsOpen} closeModal={closeFromColorModal} root={fromColorRef.current} />
      <ExportMenu isOpen={exportIsOpen} closeModal={closeExportModal} root={exportRef.current} />
    </ControlsWrapper>
  );
};

export default Controls;
