import styled from "styled-components";

export const Title = styled.h1`
  margin: 0;
  margin-right: 0.5em;
  a {
    display: flex;
    align-items: baseline;
    color: var(--brand-primary-500);
    font-family: "Poppins", "Nunito Sans", Roboto, "Open Sans", Tahoma, Geneva,
    "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    text-decoration: none;
    font-size: 1.6rem;
    img {
      align-self: center;
      margin-right: .5em;
    }
    strong {
      font-family: "Poppins", "Nunito Sans", Roboto, "Open Sans", Tahoma, Geneva,
    "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: 700;
    }
    @media (max-width: 940px) {
      font-size: 1.25rem;
    }
  }
`;

export const Subtitle = styled.p`
  color: var(--brand-primary-500);
  margin: 0;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Nav = styled.nav`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 1em;
  a {
    color: var(--brand-neutral-800);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 940px) {
    gap: 1em;
  }
`;

export const ThemeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background: var(--brand-neutral-200);
  color: var(--brand-neutral-600);
  border-radius: 50%;
  height: 2.5em;
  width: 2.5em;
  &:hover {
    background: var(--brand-neutral-300);
    cursor: pointer;
  }
`;

export const GoodBriefLink = styled.a`
  display: inline-flex;
  background: #d9f5f1;
  font-weight: 500;
  padding: 0.45em 1em;
  border-radius: 50px;
  gap: 0.5em;
  transition: background 0.2s ease;
  &:hover {
    background: #b3eae3;
    color: #086054;
    text-decoration: none;
  }
  p {
    font-weight: regular;
  }
  p, p strong {
    color: #0f9587;
    margin: 0;
    font-family: "Poppins", "Nunito Sans", Roboto, "Open Sans", Tahoma, Geneva,
    "Helvetica Neue", Helvetica, Arial, sans-serif;
    &:hover {
      text-decoration: none;
    }
  }
  img {
    align-self: center;
    height: 18px;
  }
  @media (max-width: 460px) {
    padding: .75em;
    p {
      display: none;
    }
  }
`;

export const StyledHeader = styled.header`
  background: var(--brand-neutral-100);
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--brand-neutral-200);
  padding: 0 1em;
  grid-row: header;
  grid-column: header;
  min-width: 0;
  p {
    margin: 0;
  }
  @media (max-width: 975px) {
    ${Subtitle} {
      display: none;
    }
  }
`;
