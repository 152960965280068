import { useContext } from "react";
import styled from "styled-components";
import { PaletteContext } from "@contexts/PaletteContext";
import ColorCard from "./ColorCard";

const PaletteWrapper = styled.div`
  grid-row: palette;
  grid-column: palette;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5em;
  border-right: 1px solid var(--brand-neutral-200);
  flex-grow: 1;
  padding: 3em;
  min-width: 0;
  z-index: 1;
  @media (max-width: 940px) {
    padding: 0;
    gap: 0;
  }
`;

const Palette = () => {
  const { palette } = useContext(PaletteContext);
  return (
    <PaletteWrapper>
      <ColorCard type="primary" palette={palette.primary} />
      <ColorCard type="accent" palette={palette.accent} />
      <ColorCard type="neutral" palette={palette.neutral} />
    </PaletteWrapper>
  );
};

export default Palette;
