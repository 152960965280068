import React, { useCallback, useRef, useState } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";
import styled from "styled-components";
import './styles.css';

import useClickOutside from "@hooks/useClickOutside";
import { PrimaryIconButton } from "../Buttons";

const Picker = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Swatch = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  box-shadow: 0 0 0 8px var(--brand-neutral-200);
  cursor: pointer;
  transform: translateX(50%);
  margin-left: -1em;
`;

const Popover = styled.div`
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  z-index:1;
  @media (max-width: 940px) {
    top: 0;
    transform: translateY(-100%);
  }
`;

const ColorInputWrapper = styled.div`
  input {
    background: var(--brand-neutral-200);
    color: var(--brand-neutral-800);
    border: none;
    font-size: 1em;
    min-width: 0;
    width: 200px;
    height: 50px;
    padding: 1em 2em;
    padding-left: 2.5em;
  }
  &::before {
    content: "#";
    display: block;
    position: absolute;
    color: var(--brand-neutral-800);
    top: 50%;
    left: 45px;
    transform: translateY(-50%);
  }
`;

const ConfirmButton = styled(PrimaryIconButton)`
  width: 34px;
  height: 34px;
  box-shadow: 0 0 0 8px var(--brand-neutral-200);
  transform: translateX(-50%);
  margin-right: -1em;
`;

export const ColorPicker = ({ color, onChange, onConfirm }) => {
  const popover = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(() => setIsOpen(false), []);
  useClickOutside(popover, close);

  return (
    <Picker>
      <Swatch
        style={{ backgroundColor: color }}
        onClick={() => setIsOpen(true)}
      />
      <ColorInputWrapper>
        <HexColorInput color={color} onChange={onChange} />
      </ColorInputWrapper>
      <ConfirmButton onClick={onConfirm}>
        <ion-icon name="arrow-forward-outline" size="small"></ion-icon>
      </ConfirmButton>
      {isOpen && (
        <Popover ref={popover}>
          <HexColorPicker color={color} onChange={onChange} />
        </Popover>
      )}
    </Picker>
  );
};
