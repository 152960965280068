import onecolor from "onecolor";

const wcagAA = 4.5;
const wcagAAA = 7;

const getLuminance = (hex) => {
  // https://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
  const color = onecolor(hex);

  const rLum =
    color._red <= 0.03928
      ? color._red / 12.92
      : Math.pow((color._red + 0.055) / 1.055, 2.4);
  const gLum =
    color._green <= 0.03928
      ? color._green / 12.92
      : Math.pow((color._green + 0.055) / 1.055, 2.4);
  const bLum =
    color._blue <= 0.03928
      ? color._blue / 12.92
      : Math.pow((color._blue + 0.055) / 1.055, 2.4);
  const luminance = 0.2126 * rLum + 0.7152 * gLum + 0.0722 * bLum;
  return luminance;
};

const getContrastRatio = (foregroundHex, backgroundHex) => {
  if (!foregroundHex || !backgroundHex) return false;
  const fgLum = getLuminance(foregroundHex);
  const bgLum = getLuminance(backgroundHex);

  return (Math.max(fgLum, bgLum) + 0.05) / (Math.min(fgLum, bgLum) + 0.05);
};

const isLight = (hex) => {
  // https://24ways.org/2010/calculating-color-contrast
  const color = onecolor(hex);
  const yiq =
    (color._red * 299 + color._green * 587 + color._blue * 114) / 1000;
  return yiq >= 128;
};

/**
 * Returns a color that meets accesibility guidelines from an array of
 * colors, if provided, or from black and white.
 *
 * @param {string} background
 * @param {string[]} colorOptionsArray
 * @param {boolean} findMin Whether to find the color with minimum contrast
 *                          that still passes. Otherwise returns maximum contrast.
 * @returns
 */
const getAccessibleColor = (background, colorOptionsArray, findMin) => {
  if (!background) return false;

  const minimumContrast = wcagAA;

  if (colorOptionsArray && findMin) {
    const minContrastColor = colorOptionsArray.reduce((previous, current) => {
      const prevRatio = getContrastRatio(previous, background);
      const currRatio = getContrastRatio(current, background);
      if (prevRatio < minimumContrast && currRatio < minimumContrast) {
        return null;
      }
      if (prevRatio >= minimumContrast && currRatio < minimumContrast) {
        return previous;
      }
      if (prevRatio < minimumContrast && currRatio >= minimumContrast) {
        return current;
      }
      return prevRatio < currRatio && prevRatio >= minimumContrast
        ? previous
        : current >= minimumContrast
          ? current
          : null;
    });
    if (minContrastColor) return minContrastColor;
  }

  if (colorOptionsArray) {
    const maxContrastColor = colorOptionsArray.reduce((previous, current) => {
      return getContrastRatio(previous, background) >
        getContrastRatio(current, background)
        ? previous
        : current;
    });

    if (getContrastRatio(maxContrastColor, background) >= minimumContrast) {
      return maxContrastColor;
    }
  }

  // If we can't find a better color, just return black or white
  return isLight(background) ? "000000" : "FFFFFF";
};

const checkWcagContrast = (foregroundHex, backgroundHex) => {
  const contrast = getContrastRatio(foregroundHex, backgroundHex);
  if (contrast >= wcagAAA) return 'aaa';
  if (contrast >= wcagAA) return 'aa';
  return false;
}

const copyToClipboard = (text) => {
  var dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

export { getAccessibleColor, checkWcagContrast, copyToClipboard };
