import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { PaletteContext } from '@contexts/PaletteContext';
import { ColorPicker } from "./ColorPicker";

const modalRoot = document.getElementById('modal-root');

const Modal = styled.div`
  position: absolute;
  top: 2em;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
  z-index: 1;
  &::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom: 10px solid var(--brand-neutral-200);
    top: -10px;
  }
  @media (max-width: 940px) {
    top: .5em;
    transform: translate(-50%, -100%);
    &::before {
      display: none;
    }
    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top: 10px solid var(--brand-neutral-100);
      top: 0px;
    }
  }
`;

const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--brand-neutral-200);
  color: var(--brand-neutral-700);
  border-radius: 5px 5px 0 0;
  padding: 1em 1em;
  width: 100%;
  z-index: 1;
  p {
    margin: 0;
  }
`;

const ModalContent = styled.div`
  background: var(--brand-neutral-100);
  color: var(--brand-neutral-800);
  border-radius: 0 0 5px 5px;
  padding: 1.5em;
  box-shadow: 0 18px 10px -20px var(--brand-neutral-700), 0 10px 50px -20px var(--brand-neutral-700);
  width: 100%;
  p {
    margin: 0;
  }
`;

const GenerateFromPrimary = ({ isOpen, closeModal, root }) => {
  const modalRef = useRef();
  const { palette, getPaletteFromColor } = useContext(PaletteContext);
  const [color, setColor] = useState('#' + palette.primary.shades[500]);

  const handleKeydown = useCallback((event) => {
    if (event.keyCode === 27) {
      closeModal();
    }
  }, [closeModal]);

  const handleGenerateFromColor = () => {
    console.log('generate from color: ' + color);
    getPaletteFromColor(color.substring(1));
  }

  useEffect(() => {
    setColor('#' + palette.primary.shades[500]);
  }, [palette])

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleKeydown, false);
    }
    return () => {
      document.removeEventListener('keydown', handleKeydown, false);
    };
  }, [isOpen, handleKeydown]);

  useEffect(() => {
    const handleClickOutside = e => {
      if (modalRef && modalRef.current && modalRef.current.contains(e.target)) {
        return;
      }
      closeModal();
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [closeModal]);

  return isOpen ? createPortal(
    <>
      <Modal ref={modalRef}>
        <ModalTitle>
          <p>Generate from primary color</p>
        </ModalTitle>
        <ModalContent>
          <ColorPicker color={color} onChange={setColor} onConfirm={handleGenerateFromColor} />
        </ModalContent>
      </Modal>
    </>, root || modalRoot) : null;
};

export default GenerateFromPrimary;